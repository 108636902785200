import React from 'react';
import Container from '../../Shared/Container/Container';

const CLIENTS_LOGO_DATA = [
  {
    src: require('../../../assets/images/connect/client-logos/stacks.png').default,
    alt: 'Stacks',
  },
  {
    src: require('../../../assets/images/connect/client-logos/xverselogo.png').default,
    alt: 'Bed Bath and Beyond',
  },
  {
    src: require('../../../assets/images/connect/client-logos/alexgologo.png').default,
    alt: 'Careem',
  },
  {
    src: require('../../../assets/images/connect/client-logos/polkalogo.png').default,
    alt: 'BBC World Wide',
  },
  {
    src: require('../../../assets/images/connect/client-logos/raven.png').default,
    alt: 'Raven',
  },
];

function ClientsLogoSection() {
  return (
    <section className="connect__clients-logo-section">
      <Container>
        <div className="connect__clients-logo-container">
          {CLIENTS_LOGO_DATA.map(({src, alt}, i) => (
            <div key={i} className="connect__clients-logo-img-container">
              <img key={i} className="connect__clients-logo-img" src={src} alt={alt} />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}

export default ClientsLogoSection;
